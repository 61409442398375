<ng-container *ngIf="modalData">
  <div class="modalContainerMain {{animationName}}">
    <div class="modalContainer" [ngStyle]="{'width': modalData?.width}"  [class]="modalData?.displayClass">
      <div class="modalContainerInner">
        <div class="modalContainerHeader">
          <h2 class="pageTitle">{{ title }}</h2>
          <div class="modalContainerHeaderClose" (click)="closeModal(null)">
            <mat-icon>close</mat-icon>
          </div>
        </div>
        <div class="modalContainerBody">
          <div #modalBodyContainer></div>
          <div class="modalBottomContainer" *ngIf="isNewModal">
            <div class="row formActions">
              <div class="col">
                <div class="formActionsInner">
                  <button mat-stroked-button type="button" (click)="onClose()" customButton class="mainButton defaultButton modal-defaultButton">
                    {{'cancel' | translate}}</button>
                  <button mat-flat-button type="submit" [disabled]="isSaveDisabled()" (click)="onSubmit()" customButton class="mainButton"
                    color="primary">{{ saveButtonTitle | translate}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modalContainerBackground" [@backgroundFade]="animationState"></div><!--(click)="closeModal(null)"-->  
</ng-container>

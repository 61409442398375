import { NgIf, NgStyle } from "@angular/common";
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ModalComponentTypes } from "@shared/enums";
import { ModalData } from "@shared/interfaces";
import { ModalService } from "@shared/services/helpers/modal.service";
import { MainService } from "@shared/services/main.service";
import { ChildModalBase } from '@shared/models/childModalBase';
import { Utils } from "@shared/utils";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { CustomButtonDirective } from "@shared/directives/custom-button.directive";

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
    standalone: true,
    imports: [
      CustomButtonDirective,
      NgIf,
      NgStyle,
      MatButtonModule,
      MatIcon,
      TranslateModule
    ],
    animations: [
      // Animation for the background overlay
      trigger('backgroundFade', [
        state('enter', style({ opacity: 1 })), // State when background is visible
        state('leave', style({ opacity: 0 })), // State when background is hidden
        transition('enter => leave', [
          animate('300ms ease-in')
        ]),
        transition('leave => enter', [
          style({ opacity: 0 }), // Start state before entering
          animate('300ms ease-out')
        ])
      ])
    ]
})
export class ModalComponent implements OnInit {
  @ViewChild('modalBodyContainer', { static: false }) modalBodyContainer: ElementRef;
  constructor(
    public modalService: ModalService,
    private resolver: ComponentFactoryResolver,
    private renderer2: Renderer2,
    private viewContainerRef: ViewContainerRef,
    public mainService: MainService
  ) { }

  animationState = 'leave';
  animationName = '';
  modalComponentTypes = ModalComponentTypes;
  modalData: ModalData | undefined;
  childComponentInit = false;
  componentRef: any = null;
  isNewModal: boolean = false;

  title: string = 'Loading...';
  saveButtonTitle: string = 'Save';

  ngOnInit(): void {
    const sub = this.modalService.modalServiceSubject.subscribe((res: {opened: boolean, data?: ModalData}) => {
      if (res) {
        if (res.opened) {
          this.modalData = res.data;
          setTimeout(() => {
            this.createComponent();
          });
          this.setOpenAnimation();
          this.mainService.stopBodyScrolling();
        }
      }
    });
  }

  public closeModal(data?: any): void {
    this.setCloseAnimation();
    this.animationState = 'leave';

    setTimeout(() => {
      this.componentRef.destroy();
      this.modalService.closeModal(data);
      this.mainService.enableBodyScrolling();
      this.modalData = undefined;
    }, 550);
  }

  public setOpenAnimation(): void {
    this.animationName = 'slide-in-top';
  }

  public setCloseAnimation(): void {
    this.animationName = 'slide-out-top';
  }

  public createComponent(): void {
    if (this.modalData.component) {
      const factory = this.resolver.resolveComponentFactory(this.modalData.component);
      const componentRef = this.viewContainerRef.createComponent(factory);
      this.componentRef = componentRef;
      const componentInstance = componentRef.instance;

      if (Utils.isSubclassOf(componentRef.componentType, ChildModalBase)) {
        this.isNewModal = true;
        this.title = (componentInstance as ChildModalBase).getTitle();
        this.saveButtonTitle = (componentInstance as ChildModalBase).getSaveButtonTitle();
      }
      else {
        this.isNewModal = false;
        this.title = this.modalData.title;
      }

      const componentElement = this.componentRef.location.nativeElement;
      this.modalBodyContainer.nativeElement.insertBefore(componentElement, this.modalBodyContainer.nativeElement.firstChild);

      setTimeout(() => {
        this.animationState = 'enter';
        //@ts-ignore
        const outputSub = componentRef.instance.closed.subscribe(event => {
          this.closeModal(event);
          outputSub.unsubscribe();
        });
      })
    }
  }

  onSubmit() {
    if (this.componentRef && typeof this.componentRef.instance.submit === 'function') {
      this.componentRef.instance.submit();
    }
  }

  onClose() {
    if (this.componentRef && typeof this.componentRef.instance.close === 'function') {
      this.componentRef.instance.close();
    }
  }

  isSaveDisabled() {
    if (this.componentRef && typeof this.componentRef.instance.isSaveDisabled === 'function') {
      return this.componentRef.instance.isSaveDisabled();
    }
  }
}

import { Directive, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalData } from '@shared/interfaces';
import { ModalService } from '@shared/services/helpers/modal.service';

@Directive()
export abstract class ChildModalBase {
  @Output() closed = new EventEmitter();
  abstract submit(): void;
  abstract isSaveDisabled(): boolean;

  modalData: ModalData | undefined;

  // This is not the string, but the localized string suffix that
  // gets added to 'add' or 'edit' to create the key from the strings file.
  // Like: 'addEntity'.
  title: string = 'Entity';

  // Where as this is the whole string key, that gets overridden
  // by the child class
  saveButtonTitle: string = 'save';

  constructor(
    private modalService: ModalService,
    public translateService: TranslateService
  ) { }

  public getModalData() {
    if (!this.modalData) {
      this.modalData = this.modalService.getModalData();
    }
  }

  public getTitle(): string {
    // We make sure we get the data before returning the title.
    this.getModalData();
    
    return this.translateService.instant(
      (this.modalData && this.modalData.editMode) ?
      'edit' + this.title :
      'add' + this.title);
  }

  public getSaveButtonTitle(): string {
    this.getModalData();

    return this.translateService.instant(this.saveButtonTitle);
  }

  // This method can be overridden for custom logic on close.
  public close(): void {
    this.closed.emit(null);
  }
}

export const Constants = {
  environment: "",
  inventoryApi: "",
  employeeApi: "",
  customerApi: "",
  identityApi: "",
  expensesApi: "",
  accountingApi: "",
  kitchenProductionApi: "",
  marketingApi: "",
  posApi: "",
  widgetApi: {
    baseURL: "",
    cash: {
      history: {
        endpoint: "",
        version: "",
      },
      dollarsPerTicket: {
        endpoint: "",
        version: "",
      }
    },
    cashiers: {
      status: {
        endpoint: "",
        version: "",
      },
      discounts: {
        endpoint: "",
        version: ""
      }
    },
    departments: {
      top: {
        endpoint: "",
        version: "",
      },
      list: {
        endpoint: "",
        version: "",
      },
    },
    products: {
      top: {
        endpoint: "",
        version: "",
      },
    },
    teams: {
      status: {
        endpoint: "",
        version: "",
      },
    },
    transactions: {
      history: {
        endpoint: "",
        version: "",
      },
      grossProfit: {
        endpoint: "",
        version: "",
      },
      quickStats: {
        endpoint: "",
        version: "",
      },
    },
    vendor: {
      payouts: {
        endpoint: "",
        version: ""
      }
    }
  },
  lookUpSearchTermValidation: 3,
  lookupDebounce: 400, //ms
  searchDebounce: 700, //ms
  defaultMinuteGap: 15,
  allImageAcceptTypes: ["image/png", "image/jpg", "image/jpeg"],
  phoneNumberMask: "(000)000-0000",
  ssnMask: "000-00-0000",
  phoneNumberMaskPrefix: "+1",
  phoneNumberExtentionMaskPrefix: "#",
  phoneNumberExtentionMask: "0000",
  templateCellColor: "#007BFF",
  schedulingCellColor: "#F7F5FB",
  datePipeString: "MM/dd/yyy",
  dateFriendlyPipeString: "MMM d, yyyy",
  dateAtTimePipeString: "MM/dd/yy 'at' hh:mm a",
  dateInWordsPipeString: "MMM d, yyyy, 'at' hh:mm a",
  gridDefaultPageSize: 10,
  emailFormat: "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
  // uploadImageMaxSize: 5000000, //byte
  uploadImageMaxSize: 5000, //byte
  identity: {
    connect: "connect/token",
    user: "User/resetpassword",
    loginWithOtp: "User/LoginWithOTP",
    login: "User/Login",
    refreshToken: "User/RefreshToken",
  },
  inventory: {
    vendors: "v1/vendors",
    vendorIndividual: "v1/vendors/{id}",

    vendorContacts: "v1/vendors/{id}/contacts",
    vendorContactIndividual: "v1/vendors/{vendorId}/contacts/{id}",

    vendorProductGet: "VendorProduct/Get",
    vendorProductItemDelete: "VendorProduct/Delete",
    vendorGetAllAccounts: "Vendor/GetAllAccounts",

    addressTypes: "Enumerator/AddressType",

    inventoryGetAll: "Inventory/GetAll",
    vendorGridStructure: "Vendor/GetGridStructure",
    inventoryGridStructure: "Inventory/GetGridStructure",
    mixAndMatchGridStructure: "MixAndMatch/GetGridStructure",
    mixAndMatchType: "Enumerator/MixAndMatchType",
    transferGridStructure: "Transfer/GetGridStructure",
    inStockAdjustmentGridStructure: "Adjustment/GetGridStructure",
    getTransfer: "Transfer/Get",
    getAdjustment: "Adjustment/Get",
    transferGetAll: "Transfer/GetAll",
    inStockAdjustmentGetAll: "Adjustment/GetAllAdjustments",
    adjustmentStatusesGetAll: "Enumerator/ProductAdjustmentStatus",
    adjustmentItemStatusesGetAll: "Enumerator/ProductAdjustmentItemStatus",
    adjustmentUpdateItemStatus: "Adjustment/UpdateItemStatus",
    categoryGetAll: "Category/GetAll",
    categoryAdd: "Category/AddCategory",
    categoryGet: "Category/Get",
    categoryEdit: "Category/Edit",
    categoryDelete: "Category/Delete",
    categorySettingStatusGetAll: "Enumerator/CategoryStatus",
    categoryCheckHaveProduct: "Category/CheckHaveProduct",

    priceChangeGetPricingHistory: "PriceChange/GetPricingHistory",
    priceChangeGetPricingHistoryForProduct: "PriceChange/GetPricingHistoryForProduct",
    priceChangeGetPricingHistoryGridStructure: "PriceChange/GetPricingHistoryGridStructure",

    priceChangeGetAllRetailHistory: "PriceChange/GetRetailHistory",
    priceChangeRetailGridStructure: "PriceChange/GetRetailHistoryGridStructure",

    priceChangeGetAllSalelHistory: "PriceChange/GetSaleHistory",
    priceChangeSaleGridStructure: "PriceChange/GetSaleHistoryGridStructure",

    priceChangeGetByStatuses: "PriceChange/GetByStatuses",
    priceChangeItemStatusesGetAll: "Enumerator/PriceChangeStatus",
    priceChangeUpdateItemStatus: "PriceChange/UpdateStatus",
    editPriceChangeRetailPrice: "PriceChange/EditRetailPrice",
    updatePriceChange: "PriceChange/Update",
    getPriceChange: "PriceChange/Get",
    getPriceChangePendings: "PriceChange/GetPendings",
    priceTagsPDFGenerator: "PriceChange/PrintTagsForEventIds",

    priceTagGetFilterData: "PriceTag/GetFilterData",
    priceTagGetAll: "PriceTag/GetAll",
    priceTagGetByStatus: "PriceTag/GetByStatus",
    priceTagUpdateStatus: "PriceTag/UpdateStatus",
    priceTagGetGridStructure: "PriceTag/GetGridStructure",
    priceTagStatusGetAll: "Enumerator/PriceTagStatus",

    inventoryAdd: "Inventory/Add",
    inventoryEdit: "Inventory/Edit",
    getAllEnumsConfiguration: "Configuration/GetAllEnumsConfiguration",
    itemLookup: "Inventory/Lookup",
    poLookup: "PurchaseOrder/LookUp",
    vendorLookup: "Vendor/Lookup",
    getInventoryItem: "Inventory/Get",
    getInventoryItemLinkProducts: "Inventory/GetBreakCaseHierarchy",
    getInventoryCustomerGroups: "Inventory/GetCustomerGroups",
    getInventoryPromotions: "Inventory/GetPromotions",
    printTagForProduct: "v1/products/{id}/printTag",

    getInvoicesByProductId: "Inventory/GetInvoicesByProductId",
    setVendorAsPreferred: "Inventory/SetVendorAsPreferred",
    getInvoicesByProductGridStructure:
      "Inventory/GetInvoiceByProductIdGridStructure",

    mixAndMatchGetAll: "MixAndMatch/GetAll",
    getProductForMixAndMatch: "Inventory/GetProductForMixAndMatch",
    mixAndMatchAdd: "MixAndMatch/Add",
    mixAndMatchEdit: "MixAndMatch/Edit",
    mixAndMatchGet: "MixAndMatch/Get",
    mixAndMatchDelete: "MixAndMatch/Delete",
    mixAndMatchCancel: "MixAndMatch/Cancel",
    mixAndMatchGetFilterData: "MixAndMatch/GetFilterData",

    branches: "v1/branches",
    branchIndividual: "v1/branches/current",
    branchSettings: "v1/branches/current/settings",
    addEditTransfer: "Transfer/Add",
    addAdjustment: "Adjustment/Add",
    editAdjustment: "Adjustment/Edit",
    searchForSpecialPricing: "Inventory/SearchForSpecialPricing",
    getRetailPriceGridStructure: "Pricing/GetRetailPriceGridStructure",
    getAllRetailPrices: "Pricing/GetAllRetailPrices",
    getActiveRetailPrices: "Pricing/GetActiveRetailPrices",

    updateCost: "Pricing/UpdateCost",

    addRetailPrice: "Pricing/AddRetailPrice",
    editRetailPrice: "Pricing/EditRetailPrice",
    getRetailPrice: "Pricing/GetRetailPrice",

    deleteRetailPrice: "Pricing/DeleteRetailPrice",
    getSalePrice: "Pricing/GetSalePrice",
    deleteSalePrice: "Pricing/DeleteSalePrice",
    cancelSalePrice: "Pricing/CancelSalePrice",
    getSalePriceGridStructure: "Pricing/GetSalePriceGridStructure",
    getAllSalePrices: "Pricing/GetAllSalePrices",
    getActiveSalePrice: "Pricing/GetActiveSalePrice",

    addSalePrice: "Pricing/AddSalePrice",
    editSalePrice: "Pricing/EditSalePrice",
    getProductCurrentRetailPrice: "Pricing/GetProductCurrentRetailPrice",
    salePriceTypeGetAll: "Enumerator/SalePriceType",
    getAllDiscount: "Inventory/GetAllDiscount",

    vendorInvoices: "v1/vendorInvoices",
    vendorInvoiceIndividual: "v1/vendorInvoices/{id}",

    // TODO: Restructure
    invoiceGetAll: "Invoice/GetAll",
    invoiceGridStructure: "Invoice/GetGridStructure",
    invoiceAdd: "Invoice/Add",
    invoiceAddToDraft: "Invoice/AddToDraft",
    invoiceEditDraft: "Invoice/EditDraft",
    invoiceDelete: "Invoice/Delete",
    invoiceEdit: "Invoice/Edit",
    invoiceGet: "Invoice/Get",
    invoiceGetFilterData: "Invoice/GetFilterData",
    
    invoiceReasonGetAll: "Enumerator/InvoiceReason",
    returnToVendorGet: "ReturnToVendor/Get",
    returnToVendorGetAll: "ReturnToVendor/GetAll",
    returnToVendorGridStructure: "ReturnToVendor/GetGridStructure",
    returnToVendorEdit: "ReturnToVendor/Update",
    returnToVendorDelete: "ReturnToVendor/Delete",
    returnToVendorCancel: "ReturnToVendor/Cancel",
    returnToVendorAdd: "ReturnToVendor/Add",
    returnToVendorDeleteUploadFile: "ReturnToVendor/DeleteReturnToVendorUploadFile",
    getProductLastPrice: "Inventory/GetProductLastPrice",
    getForInvoice: "Inventory/GetForInvoice",
    vendorPaymentGet: "VendorPayment/Get",
    vendorPaymentAdd: "VendorPayment/Add",
    vendorPaymentGetAll: "VendorPayment/GetAll",
    vendorPaymentGetGridStructure: "VendorPayment/GetGridStructure",
    getForPayment: "Invoice/GetForPayment",
    purchaseOrderAdd: "PurchaseOrder/Add",
    purchaseOrderEdit: "PurchaseOrder/Edit",
    purchaseOrderGridStructure: "PurchaseOrder/GetGridStructure",
    purchaseOrderGetAll: "PurchaseOrder/GetAll",
    purchaseOrderGet: "PurchaseOrder/Get",
    purchaseOrderDelete: "PurchaseOrder/Delete",
    purchaseOrderGetFilterData: "PurchaseOrder/GetFilterData",
    productUnitTypeGetAll: "Enumerator/ProductUnitType",
    productStatusGetAll: "Enumerator/ProductStatus",
    productSoldByGetAll: "Enumerator/SoldBy",
    productReturnPolicyGetAll: "Enumerator/ReturnPolicy",
    branchTypeGetAll: "Enumerator/BranchType",
    categoryStatusGetAll: "Enumerator/CategoryStatus",
    deliveryTypeGetAll: "Enumerator/DeliveryType",
    identifierTypeGetAll: "Enumerator/VendorIdentifierType",
    invoiceStatusGetAll: "Enumerator/InvoiceStatus",
    poDeliveryMethodGetAll: "Enumerator/PODeliveryMethod",
    purchaseOrderStatusGetAll: "Enumerator/PurchaseOrderStatus",
    returnMethodGetAll: "Enumerator/ReturnMethod",
    returnsAcceptedGetAll: "Enumerator/ReturnsAccepted",
    vendorSmartReorderGetAll: "Enumerator/VendorSmartReorder",
    rTVLocationGetAll: "Enumerator/RTVLocation",
    rTVReasonGetAll: "Enumerator/RTVReason",
    rTVStatusGetAll: "Enumerator/RTVStatus",
    saleTypeGetAll: "Enumerator/SaleType",
    vendorRoleGetAll: "VendorRole/GetAll",
    purchaseOrderAddToDraft: "PurchaseOrder/AddToDraft",
    purchaseOrderEditDraft: "PurchaseOrder/EditDraft",
    stateGetAll: "Enumerator/State",
    paymentTypeGetAll: "Enumerator/PaymentType",
    termsGetAll: "Enumerator/BillerTerms",
    discountTypeGetAll: "Enumerator/DiscountType",

    shrinkGet: "Shrink/Get",
    shrinkGetAll: "Shrink/GetAll",
    shrinkGetGridStructure: "Shrink/GetGridStructure",
    shrinkAdd: "Shrink/Add",
    shrinkDelete: "Shrink/Delete",
    shrinkEdit: "Shrink/Edit",
    shrinkReasonGetAll: "Enumerator/ShrinkReason",
    stockGridStructure: "Stock/GetItemStockGridStructure",
    stockGetItemStock: "Stock/GetItemStock",

    taxGetAll: "Tax/GetAll",
    taxGet: "Tax/Get",
    taxAdd: "Tax/Add",
    taxEdit: "Tax/Edit",
    taxDelete: "Tax/Delete",
    taxGetGridStructure: "Tax/GetGridStructure",
    taxGetList: "Tax/GetTaxList",

    printerGetAll: "Printer/GetAll",
    printerGet: "Printer/Get",
    printerAdd: "Printer/Add",
    printerEdit: "Printer/Edit",
    printerDelete: "Printer/Delete",
    printerGetGridStructure: "Printer/GetGridStructure",

    labelSizeGetAll: "LabelSize/GetAll",

    priceTagTemplateGetAll: "PriceTagTemplate/GetAll",
    priceTagTemplateGet: "PriceTagTemplate/Get",
    priceTagTemplateAdd: "PriceTagTemplate/Add",
    priceTagTemplateEdit: "PriceTagTemplate/Edit",
    priceTagTemplateDelete: "PriceTagTemplate/Delete",
    priceTagTemplateGetGridStructure: "PriceTagTemplate/GetGridStructure",
    priceTagTemplateGetForList: "PriceTagTemplate/GetForList",

    crvGetAll: "CRV/GetAll",
    crvGet: "CRV/Get",
    crvAdd: "CRV/Add",
    crvEdit: "CRV/Edit",
    crvDelete: "CRV/Delete",
    crvGetGridStructure: "CRV/GetGridStructure",
    crvGetList: "CRV/GetList",

    deliveredTypesGetAll: "Enumerator/DeliveredType",
    weightTypeGetAll: "Enumerator/ProductUnitType",
    ageRestrictionGetAll: "Enumerator/AgeRestriction",
    getForMerge: "Inventory/GetForMerge",
    getAllBreakCaseTypes: "Enumerator/BreakCaseType",
    getAllProductUnityGroupings: "ProductUnitType/GetAllGroupings",
    merge: "Inventory/Merge",
    pricingAdd: "Pricing/Add",
    pricingUpdate: "Pricing/Update",
    pricingGet: "Pricing/Get",
    pricingCancel: "Pricing/Cancel",
    pricingGetAll: "Pricing/GetAll",
    pricingGetGridStructure: "Pricing/GetGridStructure",

    uploadImageForProduct: "Inventory/UploadImageForProduct",
    removeImageFromProduct: "Inventory/RemoveImageFromProduct",

    eslTagGetAll: "Inventory/GetAllESLTags",
    eslTagAdd: "Inventory/AddESLTag",
    eslTagEdit: "Inventory/UpdateESLTag",
    eslTagDelete: "Inventory/DeleteESLTag"
  },
  employee: {
    addAuthenticationKey: "Employee/AddAuthenticationKey",
    editAuthenticationKey: "Employee/EditAuthenticationKey",
    removeAuthenticationKey: "Employee/RemoveAuthenticationKey",
    employeeAdd: "Employee/Add",
    employeeEdit: "Employee/Edit",
    employeeGetGridStructure: "Employee/GetGridStructure",
    employeeGetAll: "Employee/GetAll",
    employeeGet: "Employee/Get",
    employeeLookup: "Employee/LookUp",
    employeeClockInOutGridStructure: "EmployeeAttendance/GetGridStructure",
    employeeClockInOutGetAll: "EmployeeAttendance/GetAll",
    employeeStatusGetAll: "Enumerator/EmployeeStatus",
    employeeGetAllForScheduling: "Employee/GetAllForScheduling",
    getDepartment: "Department/GetDepartment",
    getAllDepartments: "Department/GetAllDepartments",
    addDepartment: "Department/AddDepartment",
    editDepartment: "Department/EditDepartment",
    deactivateDepartment: "Department/DeactivateDepartment",
    addRole: "Department/AddRole",
    editRole: "Department/EditRole",
    deactivateRole: "Department/DeactivateRole",
    applicablePaymentMethodGetAll: "Enumerator/ApplicablePaymentMethod",
    employeeIdentifierTypeGetAll: "Enumerator/EmployeeIdentifierType",
    healthCareGetAll: "Enumerator/HealthCareStatus",
    payRateTypeGetAll: "Enumerator/PayRateType",
    payrollCompanyGetAll: "Enumerator/PayrollCompany",
    positionTypeGetAll: "Enumerator/PositionType",
    salaryBasisGetAll: "Enumerator/SalaryBasis",
    roleGetAll: "Department/GetAllRoles",
    schedulingTemplateAdd: "SchedulingTemplate/Edit",
    schedulingTemplateEdit: "SchedulingTemplate/Edit",
    schedulingTemplateGetAll: "SchedulingTemplate/GetAll",
    schedulingTemplateGet: "SchedulingTemplate/Get",
    schedulingGet: "Schedule/Get",
    schedulingGetAssignmentsByDate: "Schedule/GetAssignmentsByDate",
    schedulingAdd: "Schedule/Edit",
    schedulingEdit: "Schedule/Edit",
    schedulingDuplicate: "Scheduling/Duplicate",
    schedulingRepeatUntil: "Scheduling/RepeatUntil",
    payrollGetAll: "Payroll/GetAll",
    payrollGridStructure: "Payroll/GetGridStructure",
    payrollGetEmployeePayroll: "Payroll/GetEmployeePayroll",
    payrollDelete: "Payroll/DeletePayroll",
    payrollEdit: "Payroll/EditPayroll",
    payrollConfirm: "Payroll/ConfirmPayroll",

    employeeRoleGet: "EmployeeRole/Get",
    employeeRoleGetAll: "EmployeeRole/GetAll",
    employeeRoleAdd: "EmployeeRole/Add",
    employeeRoleDelete: "EmployeeRole/Delete",
    employeeRoleEdit: "EmployeeRole/Update",
    possiblePermissionsGetAll: "EmployeeRole/GetAllPermissions",

    userGetProfile: "User/GetProfile",
    userVerifyPassword: "User/VerifyPassword",
  },
  customer: {
    customerAdd: "Customer/Add",
    customerEdit: "Customer/Edit",
    customerDelete: "Customer/Delete",
    customerGet: "Customer/Get",
    customerGetGridStructure: "Customer/GetGridStructure",
    customerGetAll: "Customer/GetAll",
    membershipTypeGet: "MembershipType/Get",
    membershipTypeGetAll: "MembershipType/GetAll",

    customerGroupAdd: "CustomerGroup/Add",
    customerGroupEdit: "CustomerGroup/Edit",
    customerGroupGet: "CustomerGroup/Get",
    customerGroupGridStructure: "CustomerGroup/GetGridStructure",
    customerGroupGetAll: "CustomerGroup/GetAll",
    customerGroupStatusGetAll: "Enumerator/CustomerGroupStatus",
    getCustomerGroupList: "CustomerGroup/GetCustomerGroupList",
  },
  expenses: {
    billerAdd: "Biller/Add",
    billerEdit: "Biller/Edit",
    billerDelete: "Biller/Delete",
    billerGet: "Biller/Get",
    billerGetGridStructure: "Biller/GetGridStructure",
    billerGetAll: "Biller/GetAll",
    billersGetAll: "Biller/GetAllBillers",
    billerPaymentAdd: "BillerPayment/Add",
    getBillPayments: "BillerPayment/GetBillPayments",

    billAdd: "Bill/Add",
    billEdit: "Bill/Edit",
    billDelete: "Bill/Delete",
    billGet: "Bill/Get",
    billGetGridStructure: "Bill/GetGridStructure",
    billGetAll: "Bill/GetAll",
    getBillCategory: "Enumerator/BillCategory",
    getAllBillType: "Enumerator/BillType",
    expenseAdd: "Expense/Add",
    expensesEdit: "Expense/Edit",
    expenseDelete: "Expense/Delete",
    expenseGet: "Expense/Get",
    expenseGetGridStructure: "Expense/GetGridStructure",
    expenseGetAll: "Expense/GetAll",
    expensePaymentAdd: "ExpensePayment/Add",
    getExpensePayments: "ExpensePayment/GetExpensePayments",

    incomeSources: "v1/incomeSources",
    incomeSourcesIndividual: "v1/incomeSources/{id}",

    incomes: "v1/incomes",
    incomeIndividual: "v1/incomes/{id}",

    clientInvoices: "v1/client/invoices",
    clientInvoiceIndividual: "v1/client/invoices/{id}",

    invoiceGetCategories: "Enumerator/InvoiceARCategory",
    invoicePaymentAdd: "ClientPayment/Add",
    getInvoiceARPayments: "ClientPayment/GetInvoiceARPayments",

    clients: "v1/clients",
    clientsGetList: "v1/clients/select",
    clientIndividual: "v1/clients/{id}",

    plaidGetLinkToken: "Plaid/GetLinkToken",
    authenticationPlaid: "Plaid/AuthenticationPlaid",
    plaidGetBalances: "Plaid/GetBalances",
    plaidCheckConnect: "Plaid/CheckConnect",
    repeatInterval: "Enumerator/RepeatInterval",
  },
  accounting: {
    paymentSourceGet: "Account/Get",
    paymentSourceList: "Account/GetList",
    paymentSourceGetAll: "Account/GetAll",
    paymentSourceAdd: "Account/Add",
    paymentSourceEdit: "Account/Edit",
    paymentSourceDelete: "Account/Delete",
    paymentSourceGetGridStructure: "Account/GetGridStructure",
    getListBySystemCode: "Account/GetListBySystemCode",

    transactionGetGridStructure: "Account/GetPaymantSourcesByIdGridStructure",
    getAllPaymentsById: "Account/GetAllPaymentsById",
    
    transactions: "v1/transactions",
    transaction: "v1/transactions/{id}",

    accountTypeGetAll: "Enumerator/AccountType",
    accountTypeGet: "AccountType/Get",
    accountUsingTypeGetAll: "Enumerator/UsingType",
    accountStatusGetAll: "Enumerator/AccountStatus",

    balanceTransferAdd: "BalanceTransfer/Add",
    balanceTransferGet: "BalanceTransfer/Get",
    balanceTransferGetGridStructure: "BalanceTransfer/GetGridStructure",
    balanceTransferGetAll: "BalanceTransfer/GetAll",
    accountAdjustmentAdd: "v1/accounts/{id}/balance",

    ownerAdd: "Owner/Add",
    ownerEdit: "Owner/Edit",
    ownerDelete: "Owner/Delete",
    ownerGet: "Owner/Get",
    ownerGetGridStructure: "Owner/GetGridStructure",
    ownerGetAll: "Owner/GetAll",
    ownerResonCode: "Enumerator/OwnerReasonCode",

    ownerTransactionAdd: "OwnerTransaction/Add",
    ownerTransactionEdit: "OwnerTransaction/Edit",
    ownerTransactionGet: "OwnerTransaction/Get",
    ownerTransactionGetGridStructure: "OwnerTransaction/GetGridStructure",
    ownerTransactionGetAll: "OwnerTransaction/GetAll",
    ownerActionType: "Enumerator/OwnerActionType",

    registerIncomeGet: "RegisterIncome/Get",
    registerIncomeGetAll: "v1/registerIncomes",
    registerIncomeVerify: "RegisterIncome/Verify",
  },
  kitchenProduction: {
    recipeGet: "RecipeTemplate/Get",
    recipeAdd: "RecipeTemplate/Add",
    recipeEdit: "RecipeTemplate/Edit",
    recipeDelete: "RecipeTemplate/Delete",
    recipeGetAll: "RecipeTemplate/GetAll",
    recipeGridStructure: "RecipeTemplate/GetGridStructure",
    recipeGetAllList: "RecipeTemplate/GetAllRecipes",
    productionAdd: "RecipeProduction/Add",
    productionEdit: "RecipeProduction/Edit",
    productionDelete: "RecipeProduction/Delete",
    productionGet: "RecipeProduction/Get",
    productionGetAll: "RecipeProduction/GetAll",
    productionGridStructure: "RecipeProduction/GetGridStructure",
    calculateItemsCountByItemNumber:
      "RecipeProduction/CalculateItemsCountByItemNumber",
    moveToInventoryAdd: "MoveToInventory/Add",
    moveToInventoryGet: "MoveToInventory/Get",
    moveToInventoryGetAll: "MoveToInventory/GetAll",
    moveToInventoryGetGridStructure: "MoveToInventory/GetGridStructure",
    moveToInventoryCancel: "MoveToInventory/Cancel",
    recipeProductionStatusGetAll: "Enumerator/RecipeProductionStatus",
  },
  marketing: {
    couponGet: "Coupon/Get",
    couponAdd: "Coupon/Add",
    couponEdit: "Coupon/Edit",
    couponGetAll: "Coupon/GetAll",
    couponGridStructure: "Coupon/GetGridStructure",
  },
  pos: {
    // v1
    devices: "v1/devices",
    deviceServers: "v1/devices/servers",
    device: "v1/devices/{id}",
    deviceActivation: "v1/devices/activate",
    deviceClaim: "v1/devices/{id}/claim",
    deviceLogoutUser: "v1/devices/{id}/logoutUser",
    deviceAttributes: "v1/devices/{id}/attributes",
    deviceAttribute: "v1/devices/{id}/attributes/{attributeId}",
    deviceEvents: "v1/devices/{id}/events",
    deviceUpdates: "v1/devices/{id}/updates",
    deviceVirtuals: "v1/devices/{id}/virtuals",

    // Neutral
    posLookupCategoryAdd: "PosLookupCategory/Add",
    posLookupCategoryEdit: "PosLookupCategory/Edit",
    posLookupCategoryDelete: "PosLookupCategory/Delete",
    posLookupCategoryGetAll: "PosLookupCategory/GetAll",
    posLookupCategoryGet: "PosLookupCategory/Get",
    posLookupCategoryUpdateOrder: "PosLookupCategory/UpdateCategoryOrder",

    endOfShiftGet: "EndOfShift/Get",
    endOfShiftGetAll: "EndOfShift/GetAll",

    accountGetAssigned: "v1/account/assignedTills",

    endOfShiftGetCurrent: "v1/endOfShift/current",
    endOfShiftGetCurrentApprover: "v1/endOfShift/current/{id}",

    endOfShiftAdd: "EndOfShift/Add",
    endOfShiftEdit: "EndOfShift/Edit",
    endOfShiftGetApproved: "EndOfShift/Approved",
    endOfShiftGetReceipt: "EndOfShift/GetReceiptForEndOfShift",
    testingSetUpEndOfShift: "Testing/SetupEndOfShift",
    employeeGetEmployeesWithPermission: "Employee/GetEmployeesWithPermission",
    employeeVerifyPassword: "Employee/VerifyPassword",
  },
};

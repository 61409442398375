import { DateTime } from "luxon";

import { formatCurrency, KeyValue } from "@angular/common";

import { CustomTime, SystemCode, WeekDays } from "./enums";
import { DiscountType } from "./statuses.model";
import { HttpParams } from "@angular/common/http";

export class Utils {
  constructor() {}

  static generateUniqueUuId(length: number): string {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  static yyyy_mm_dd(date: any, seperator?: any) {
    let sep = seperator ? seperator : "/";
    let now = new Date(date);
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1);
    if (month.length == 1) {
      month = "0" + month;
    }
    let day = "" + now.getDate();
    if (day.length == 1) {
      day = "0" + day;
    }
    return year + sep + month + sep + day;
  }

  static mm_dd_yyyy(date: any, seperator?: any) {
    let sep = seperator ? seperator : "/";
    let now = new Date(date);
    let year = "" + now.getFullYear();
    let month = "" + (now.getMonth() + 1);
    if (month.length == 1) {
      month = "0" + month;
    }
    let day = "" + now.getDate();
    if (day.length == 1) {
      day = "0" + day;
    }
    // return year + sep + month + sep + day;
    return month + sep + day + sep + year;
  }

  static getGMTOffset() {
    // Get the timezone offset in minutes
    const offsetMinutes = new Date().getTimezoneOffset();

    // Convert to hours and minutes
    const hours = Math.floor(Math.abs(offsetMinutes) / 60);
    const minutes = Math.abs(offsetMinutes) % 60;

    // Format the offset as "+HH:MM" or "-HH:MM"
    const sign = offsetMinutes >= 0 ? "-" : "+";
    return sign + this.padZero(hours) + ":" + this.padZero(minutes);
  }

  static padZero(number) {
    // Helper function to pad single-digit numbers with a leading zero
    return number < 10 ? "0" + number : number;
  }

  static getFullDateTime(
    dateString: any,
    timeSting12: any,
    addGMT?: boolean,
  ): any {
    let str = `${this.yyyy_mm_dd(dateString, "-")}T${this.convertTo24Hour(
      timeSting12,
    )}`;
    if (addGMT) {
      str = `${str}${this.getGMTOffset()}`;
    }
    return str;
  }

  static getFullDateTimeForRequest(dateString: any, addGMT?: boolean): any {
    let str = `${this.formatDateToISOString(dateString)}`;
    if (addGMT) {
      str = `${str}${this.getGMTOffset()}`;
    }
    return str;
  }

  static changeDateToISOFormat(date: any): any {
    return new Date(date).toISOString();
  }

  static changeDateToUTCFormat(date: any): any {
    return new Date(date).toUTCString();
  }

  static formatDateToISOString(inputDate) {
    const dateObject = new Date(inputDate);

    if (isNaN(dateObject.getTime())) {
      throw new Error("Invalid date input");
    }

    const year = dateObject.getFullYear().toString().padStart(4, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");
    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const seconds = dateObject.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  static yyyy_mm_dd_hh_mm_ss(date: any) {
    var d = new Date(),
      dformat =
        [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
        " " +
        [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");
    return dformat;
  }

  static getFullDateByTime(timeString: string): any {
    let dateString = `1970-01-01 ${timeString}`;
    return new Date(dateString);
  }

  static getFullDateByTimeAsString(timeString: string): string {
    return `1970-01-01 ${timeString}`;
  }

  static convertTo24Hour(timeVal) {
    if (timeVal) {
      if (timeVal.includes("AM") || timeVal.includes("PM")) {
        var time = timeVal;
        var hours = Number(time.match(/^(\d+)/)[1]);
        var minutes = Number(time.match(/:(\d+)/)[1]);
        var AMPM = time.match(/\s(.*)$/)[1];
        if (AMPM == "PM" && hours < 12) hours = hours + 12;
        if (AMPM == "AM" && hours == 12) hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10) sHours = "0" + sHours;
        if (minutes < 10) sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes + ":00";
      } else {
        return timeVal;
      }
    } else {
      return null;
    }
  }

  static convertTo12Hour(time) {
    if (time) {
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    } else {
      return null;
    }
  }

  static mm_dd_yyyyWithSlashes(date: any) {
    if (date) {
      let now = new Date(date);
      let year = "" + now.getFullYear();
      let month = "" + (now.getMonth() + 1);
      if (month.length == 1) {
        month = "0" + month;
      }
      let day = "" + now.getDate();
      if (day.length == 1) {
        day = "0" + day;
      }
      // return year + "/" + month + "/" + day;
      // return day + "/" + month + "/" + year;
      return month + "/" + day + "/" + year;
    } else return null;
  }

  static replaceSpaceToTInDateString(dateString: string): string {
    return dateString.replace(" ", "T");
  }

  static getIdBySystemCode(array: any[], systemCode: string): number {
    let found = array.find((item) => item.systemCode == systemCode);
    if (found) {
      return found.id;
    } else {
      return null;
    }
  }

  static getSystemCodeById(array: any[], id: number): string {
    let found = array.find((item) => item.id == id);
    if (found) {
      return found.systemCode || null;
    } else {
      return null;
    }
  }

  static getNameBySystemCodeId(array: any[], id: number): string {
    let found = array.find((item) => item.id == id);
    if (found) {
      return found.name || null;
    } else {
      return null;
    }
  }

  static calculateProductPrices(
    data: {
      cost: number;
      retailPrice: number;
      discountType: number;
      discountUnit: number;
      salePrice: number;
    },
    inputName: string,
    discountTypes: DiscountType[],
  ): any {
    let dataToReturn = {
      discountUnit: data.discountUnit || 0,
      salePrice: data.salePrice || 0,
      mup: 0,
      margin: 0,
      profit: 0,
    };
    if (inputName == "salePrice") {
      if (
        data.discountType ==
          this.getIdBySystemCode(discountTypes, SystemCode.AMOUNT) ||
        data.discountType ==
          this.getIdBySystemCode(discountTypes, SystemCode.FIXED)
      ) {
        dataToReturn.discountUnit = data.retailPrice - data.salePrice;
      } else if (
        data.discountType ==
        this.getIdBySystemCode(discountTypes, SystemCode.PERCENT)
      ) {
        dataToReturn.discountUnit =
          (100 * (data.retailPrice - data.salePrice)) / data.retailPrice;
      }
    } else {
      if (
        data.discountType ==
          this.getIdBySystemCode(discountTypes, SystemCode.AMOUNT) ||
        data.discountType ==
          this.getIdBySystemCode(discountTypes, SystemCode.FIXED)
      ) {
        dataToReturn.salePrice = data.retailPrice - data.discountUnit;
      } else if (
        data.discountType ==
        this.getIdBySystemCode(discountTypes, SystemCode.FIXED)
      ) {
        dataToReturn.salePrice =
          data.retailPrice - (data.retailPrice * data.discountUnit) / 100;
      }
    }
    return Object.assign({
      ...dataToReturn,
      ...this.calculateMupMarginProfit(data.cost, dataToReturn.salePrice),
    });
  }

  static calculateMupMarginProfit(
    cost: number,
    currentPrice: number,
  ): { mup: number; margin: number; profit: number } {
    let profit = currentPrice - cost;
    let mup = parseFloat(((profit / cost) * 100).toFixed(2));
    let margin;
    if (currentPrice == 0) {
      margin = 0;
    } else {
      margin = parseFloat(((profit / currentPrice) * 100).toFixed(2));
    }
    return { profit: profit, margin: margin, mup: mup };
  }

  static removeStartValuesFormBase64(base64: string): string {
    return base64.split(",")[1];
  }

  static getFileExtension(file: File): string {
    return `.${file.type.split("/")[1]}`;
  }

  static findObjectById(array: any[], id: number) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        return array[i];
      }
      if (array[i].subcategories) {
        const result = this.findObjectById(array[i].subcategories, id);
        if (result) {
          return result;
        }
      }
      if (array[i].childrens) {
        const result = this.findObjectById(array[i].childrens, id);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  static replaceObjectById(array: any[], id: number, objectForReplce: any) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        let result = array[i];
        if (result) {
          result = objectForReplce;
          array[i] = result;
        }
      }
      if (array[i].subcategories) {
        this.replaceObjectById(array[i].subcategories, id, objectForReplce);
      }
      if (array[i].childrens) {
        this.replaceObjectById(array[i].childrens, id, objectForReplce);
      }
    }
    return array;
  }

  static getAmountByPercent(total: number, percent: number): any {
    let result = 0;
    if (total) {
      if (percent) {
        result = (total * percent) / 100;
      }
    }
    return result;
  }

  static getPercentByAmount(total: number, amount: number): any {
    let result = 0;
    if (amount) {
      if (total) {
        result = (amount * 100) / total;
      }
    }
    return result;
  }

  static getEnumNames(value: number, enumArr: any[]): string {
    const names = [];
    const index = enumArr.findIndex((e) => e.id == value);
    if (index > -1) {
      names.push(enumArr[index].name);
    }
    return names.join() || "Unknown";
  }

  static recursiveChildDeletee(data, id) {
    data.some(function iter(o, i, a) {
      if (o.id === id) {
        a.splice(i, 1);
        return true;
      }
      return o.childrens && o.childrens.some(iter);
    });
  }

  static calculateTimeInterval(startTime: any, endTime: any) {
    const start = new Date(`01/01/2021 ${startTime}`);
    const end = new Date(`01/01/2021 ${endTime}`);
    // @ts-ignore
    const diffInMs = end - start;
    const diffInHours = diffInMs / (1000 * 60);
    return diffInHours;
  }

  static calculateDateTimeInterval(startDate: any, endDate: any) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    // @ts-ignore
    const diffInMs = end - start;
    const diffInMinutes = diffInMs / (1000 * 60);
    return diffInMinutes;
  }

  static isWithinDateRange(currentDate, startDate, endDate) {
    let currentDateTime = new Date(currentDate).getTime();
    let startDateTime = new Date(startDate).getTime();
    let endDateTime = new Date(endDate).getTime();
    return currentDateTime >= startDateTime && currentDateTime <= endDateTime;
  }

  static isWithinFuture(currentDate, startDate) {
    let currentDateTime = new Date(currentDate).getTime();
    let startDateTime = new Date(startDate).getTime();
    return currentDateTime < startDateTime;
  }

  static isWithinPast(currentDate, endDate) {
    let currentDateTime = new Date(currentDate).getTime();
    let endDateTime = new Date(endDate).getTime();
    return currentDateTime > endDateTime;
  }

  static getHourByMinute(minute) {
    let h = Math.floor(minute / 60);
    let min = Math.round(minute % 60);
    if (h > 0) {
      return `${h}h ${min}m`;
    } else {
      return `${min}m`;
    }
  }

  static camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      .replace(/\s+/g, "");
  }

  static camelCaseToNormalString(str) {
    const text = str;
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  static getTime24(dateTime: any, withOutSeconds?: boolean) {
    const date = new Date(dateTime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    let returnStr = `${hours}:${minutes}`;
    if (!withOutSeconds) {
      returnStr = `${returnStr}:${seconds}`;
    }
    return returnStr;
  }

  static getTime24WithoutSeconds(timeString: string): string {
    let splited = timeString.split(":");
    return `${splited[0]}:${splited[1]}`;
  }

  static addUrlParams(url: string, key: string, value: any): string {
    try {
      let urlObj = new URL(url);

      let params = new URLSearchParams(urlObj.search);

      if (params.has(key)) {
        params.delete(key); // Delete the existing key
      }

      params.set(key, value); // Set the new key-value pair

      urlObj.search = params.toString();
      // Get the updated URL string
      return urlObj.toString();
    } catch (e) {
      console.error(e);
      return url;
    }
  }

  static replaceUrlTokens(url: string, params: { [key: string]: any } | HttpParams): string {
    let paramsObject: { [key: string]: string } = {};
  
    // Check if params is an instance of HttpParams
    if (params instanceof HttpParams) {
      // Convert HttpParams to a plain object
      params.keys().forEach(key => {
        paramsObject[key] = params.get(key) as string;
      });
    } else {
      // If not HttpParams, use it as is
      paramsObject = params as { [key: string]: string };
    }
  
    // Replace tokens in the URL using the paramsObject
    return url.replace(/{([^}]+)}/g, (match, token) => {
      return paramsObject[token] !== undefined ? paramsObject[token] : match;
    });
  }

  static getTimeArrayWithStartAndEndTimes(
    minutesGap: number,
    startTime: string,
    endTime: string,
  ): CustomTime[] {
    const times = [];
    const startDate = new Date("1970-01-01T" + startTime);
    const endDate = new Date("1970-01-01T" + endTime);
    let current = startDate;

    while (current <= endDate) {
      const value = current.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let value24 = current
        .toLocaleTimeString("en-US", { hour12: false })
        .replace(/:\d{2}\s/, " ");
      value24 = value24.replace("24", "00");
      times.push({ value, value24 });
      current = new Date(current.getTime() + minutesGap * 60000);
    }

    if (times[times.length - 1].value24 === "00:00:00") {
      times.pop();
    }

    return times;
  }

  static getTimeArray(minutesGap: number): CustomTime[] {
    let times = [];
    const start = new Date("1970-01-01T00:00:00");
    const end = new Date("1970-01-02T00:00:00");
    let current = start;
    while (current <= end) {
      const value = current.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      let value24 = current
        .toLocaleTimeString("en-US", { hour12: false })
        .replace(/:\d{2}\s/, " ");
      value24 = value24.replace("24", "00");
      times.push({ value, value24 });
      current = new Date(current.getTime() + minutesGap * 60000);
    }
    if (times[times.length - 1].value24 == "00:00:00") {
      times.pop();
    }
    return times;
  }

  static filtersPaymentSource(array: any[], paymentSourceId: number): string {
    let found = array.find((item) => item.id == paymentSourceId);
    if (found) {
      return found.systemCode || null;
    } else {
      return null;
    }
  }

  static calculateSalePrice(price: number, discountPercent: number): any {
    return price - (price * discountPercent) / 100;
  }

  static calculateProfit(
    price: number,
    marginPercent: number,
    addHundred?: boolean,
  ): any {
    let percent = addHundred ? marginPercent * 100 : marginPercent;
    return (price * percent) / 100;
  }

  static calculateMarkup(
    cost: number,
    price: number,
    addHundred?: boolean,
  ): any {
    let val = (price - cost) / cost;
    return addHundred ? val * 100 : val;
  }

  static calculateMargin(
    cost: number,
    price: number,
    shrink: number,
    addHundred?: boolean,
  ): any {
    let shrinkVal = (cost * shrink) / 100;
    let val = (price - cost - shrinkVal) / price;
    return addHundred ? val * 100 : val;
  }

  static createPaginationControls(currentPage, pageCount) {
    const paginationControls = [];

    // Determine the range of page numbers to display
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(currentPage + 2, pageCount);

    // If there are more pages to display before the start, add dots
    if (startPage > 1) {
      paginationControls.push({ page: 1, clickable: true });
      if (startPage > 2) {
        paginationControls.push({ page: "...", clickable: false });
      }
    }

    // Add the page numbers within the range
    for (let i = startPage; i <= endPage; i++) {
      paginationControls.push({ page: i, clickable: true });
    }

    // If there are more pages to display after the end, add dots
    if (endPage < pageCount) {
      if (endPage < pageCount - 1) {
        paginationControls.push({ page: "...", clickable: false });
      }
      paginationControls.push({ page: pageCount, clickable: true });
    }
    return paginationControls;
  }

  static getDayOfWeekArray(): any[] {
    return [
      { name: "Mon", value: WeekDays.Monday, checked: false },
      { name: "Tue", value: WeekDays.Tuesday, checked: false },
      { name: "Wed", value: WeekDays.Wednesday, checked: false },
      { name: "Thu", value: WeekDays.Thursday, checked: false },
      { name: "Fri", value: WeekDays.Friday, checked: false },
      { name: "Sat", value: WeekDays.Saturday, checked: false },
      { name: "Sun", value: WeekDays.Sunday, checked: false },
    ];
  }

  static formatNumber(param) {
    if (typeof param === "string") {
      param = parseFloat(param);
    }

    if (typeof param === "number" && !isNaN(param)) {
      param = param.toFixed(2);
      param = parseFloat(param);
    }

    return param;
  }
  static calculateDropdownPositions(elementPosition, dropdownHeight, space) {
    const viewportHeight = window.innerHeight;
    const distanceFromBottom = viewportHeight - elementPosition.bottom;

    const dropdownPositions = {
      top: {
        top: elementPosition.top - (dropdownHeight + 10),
        left: elementPosition.left,
        animationName: "scale-in-ver-bottom",
      },
      bottom: {
        top: elementPosition.top + space,
        left: elementPosition.left,
        animationName: "scale-in-ver-top",
      },
    };

    if (distanceFromBottom < dropdownHeight) {
      // Open dropdown from top if closer to the bottom of viewport
      return dropdownPositions.top;
    } else {
      // Open dropdown from bottom if there is enough space
      return dropdownPositions.bottom;
    }
  }

  static getMonthLastDay(date: Date): any {
    let currentDate = new Date(date);
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(0);
    return currentDate;
  }

  static timeToMinutes(timeStr) {
    const [hoursStr, minutesStr] = timeStr.split(":");
    const hours = parseInt(hoursStr);
    const minutes = parseInt(minutesStr);

    return hours * 60 + minutes;
  }

  static minutesBetweenTimes(startTime, endTime) {
    const startMinutes = this.timeToMinutes(startTime);
    const endMinutes = this.timeToMinutes(endTime);

    // Handle the case when the endTime is on the next day (e.g., startTime = 23:00, endTime = 1:00)
    if (endMinutes < startMinutes) {
      return endMinutes + 24 * 60 - startMinutes;
    }

    return endMinutes - startMinutes;
  }

  static addMinutesToTime(startTime: string, minutes: number): string {
    const [startHour, startMinute, startSecond] = startTime
      .split(":")
      .map(Number);

    let totalMinutes = startHour * 60 + startMinute + minutes;
    const newHour = Math.floor(totalMinutes / 60) % 24;
    const newMinute = totalMinutes % 60;

    return `${this.padWithZero(newHour)}:${this.padWithZero(
      newMinute,
    )}:${this.padWithZero(startSecond)}`;
  }

  static removeMinutesFromTime(endTime: string, minutes: number): string {
    const [endHour, endMinute, endSecond] = endTime.split(":").map(Number);

    let totalMinutes = endHour * 60 + endMinute - minutes;
    if (totalMinutes < 0) {
      // Handle the case when the result goes negative
      totalMinutes += 24 * 60; // Assuming a 24-hour time format
    }

    const newHour = Math.floor(totalMinutes / 60) % 24;
    const newMinute = totalMinutes % 60;

    return `${this.padWithZero(newHour)}:${this.padWithZero(
      newMinute,
    )}:${this.padWithZero(endSecond)}`;
  }

  static padWithZero(num: number): string {
    return num.toString().padStart(2, "0");
  }

  static isEventValid(newStartTime, newEndTime, oldStartTime, oldEndTime) {
    // Convert time strings to minutes for easier comparison

    const newStartMinutes = this.timeToMinutes(newStartTime);
    const newEndMinutes = this.timeToMinutes(newEndTime);
    const oldStartMinutes = this.timeToMinutes(oldStartTime);
    const oldEndMinutes = this.timeToMinutes(oldEndTime);

    if (
      (newStartMinutes >= oldStartMinutes &&
        newStartMinutes <= oldEndMinutes) ||
      (newEndMinutes >= oldStartMinutes && newEndMinutes <= oldEndMinutes) ||
      (newStartMinutes <= oldStartMinutes && newEndMinutes >= oldEndMinutes)
    ) {
      return true;
    }

    return false;
  }

  static tomorrowDate(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
  }

  static checkTimeAndSetAMPM(value: string): string {
    if (value) {
      const date = new Date(`2000-01-01T${value}`);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
    } else {
      return "-";
    }
  }
  /**
   * Checks whether the value is a number, or can be successfully converted to a number.
   * @param value
   * @returns true if the value is numeric, false otherwise
   */
  static isNumeric(value: unknown): boolean {
    return !isNaN(Number(value));
  }

  /**
   * Comparator method for use with Angular's KeyValuePipe to sort key-value
   * pairs in original object order.
   *
   * @returns `0` (no change in order)
   */
  static keyValueComparatorObjectOrder(): number {
    return 0;
  }

  /**
   * Comparator method for use with Angular's KeyValuePipe to sort key-value
   * pairs by value in ascending order.
   *
   * @returns `1` if `a` should be sorted after `b`, `-1` if `a` should be
   * sorted before `b`, and `0` if order should remain unchanged
   */
  static keyValueComparatorValueAsc(
    a: KeyValue<number | string, number | string>,
    b: KeyValue<number | string, number | string>,
  ): number {
    return a.value > b.value ? 1 : a.value < b.value ? -1 : 0;
  }

  /**
   * Comparator method for use with Angular's KeyValuePipe to sort key-value
   * pairs by value in descending order.
   *
   * @returns `1` if `a` should be sorted after `b`, `-1` if `a` should be
   * sorted before `b`, and `0` if order should remain unchanged
   */
  static keyValueComparatorValueDesc(
    a: KeyValue<number | string, number | string>,
    b: KeyValue<number | string, number | string>,
  ): number {
    return a.value < b.value ? 1 : a.value > b.value ? -1 : 0;
  }

  /**
   * Comparator method for use with Angular's KeyValuePipe to sort key-value
   * pairs by key in descending order.
   *
   * @returns `1` if `a` should be sorted after `b`, `-1` if `a` should be
   * sorted before `b`, and `0` if order should remain unchanged
   */
  static keyValueComparatorKeyDesc(
    a: KeyValue<number | string, number | string>,
    b: KeyValue<number | string, number | string>,
  ): number {
    return a.key < b.key ? 1 : a.key > b.key ? -1 : 0;
  }

  /**
   * Given the name of an enum and the key of an enum member, returns the
   * localization key for the enum member.
   * @param enumName a string representing the name of the enum
   * @param enumMemberKey a string representing the key of the enum member
   * @returns the localization key corresponding to the enum member
   */
  static enumToLocalizationKey(
    enumName: string,
    enumMemberKey: string,
  ): string {
    return `enum.${enumName}.${enumMemberKey}`;
  }

  /**
   * Returns the passed callback wrapped with a rate-limiting function that ensures the callback will be called at most once every `interval` milliseconds.
   * @param func The function to be throttled.
   * @param interval The interval to wait between calls.
   * @returns A wrapped version of the original callback passed in `func`.
   */
  static throttle(
    func: (...args: unknown[]) => void,
    interval: number,
  ): (...args: unknown[]) => void {
    let waiting = false;
    return function (...args) {
      if (!waiting) {
        func.apply(this, args);
        waiting = true;
        setTimeout(function () {
          waiting = false;
        }, interval);
      }
    };
  }

  static formatCurrencyNarrow(
    num: number | string,
    locale: string,
    currency: string,
    currencyCode?: string,
    maxDecimalPlaces: number = 3,
  ): string {
    if (typeof num === "string") {
      num = parseFloat(num);
    }

    let significand = num;
    let suffix = "";
    let digitsInfo = "1.0-0";
    const magnitudes = [
      { value: 1e15, suffix: "Q", decimalPlaces: 5 },
      { value: 1e12, suffix: "T", decimalPlaces: 4 },
      { value: 1e9, suffix: "B", decimalPlaces: 3 },
      { value: 1e6, suffix: "M", decimalPlaces: 2 },
      { value: 1e3, suffix: "K", decimalPlaces: 1 },
    ];
    for (const magnitude of magnitudes) {
      if (num === magnitude.value || num >= magnitude.value * 1.1) {
        significand = num / magnitude.value;
        suffix = magnitude.suffix;
        digitsInfo =
          significand % 1 === 0
            ? "1.0-0"
            : digitsInfo
              ? digitsInfo
              : `1.1-${Math.min(maxDecimalPlaces, magnitude.decimalPlaces)}`;
        break;
      }
    }
    return (
      formatCurrency(
        significand,
        locale,
        currency,
        currencyCode ?? undefined,
        digitsInfo ?? undefined,
      ) + suffix
    );
  }
  /**
   * Given a wall-clock time as a string, concretize it as an ISO DateTime,
   * based on the (optional) provided date. If no date is provided, defaults to
   * the current date.
   *
   * This function is idempotent if the `time` input is already an ISO DateTime
   * and the date portion does not match the current local date, allowing it to
   * be applied safely to the result of a previous call.
   *
   * @param time - Wall-clock time as a string (e.g. "09:00:00"), or ISO 8601
   * Time or DateTime string.
   * @param date - Date to concretize the time on
   * @returns ISO DateTime string
   */
  static concretizeTime(
    time: string,
    date: DateTime | string = DateTime.now(),
  ): string {
    if (typeof date === "string") {
      date = DateTime.fromISO(date);
    }

    // If time input is already a fully-qualified ISO DateTime string, return it as-is
    if (
      time?.indexOf("T") > 0 &&
      DateTime.fromISO(time).isValid &&
      DateTime.fromISO(time).toISODate() !== DateTime.now().toISODate()
    ) {
      return time;
    }

    return DateTime.fromISO(time)
      .set({ year: date.year, month: date.month, day: date.day })
      .toISO();
  }

  static deepCopySerializable<T extends object | Array<unknown>>(
    object: T,
  ): T {
    return JSON.parse(JSON.stringify(object));
  }

  public static isSubclassOf(child: any, parent: any): boolean {
    let proto = child.prototype;
    while (proto) {
      if (proto.constructor === parent) {
        return true;
      }
      proto = Object.getPrototypeOf(proto);
    }
    return false;
  }
}

import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { catchError, Observable, switchMap, throwError } from "rxjs";
import { AuthService } from "./auth.service";
import { Constants } from "../constants";

@Injectable({ providedIn: "root" })
export class InterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(
    httpRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const ContentType = "application/json";
    const Authorization = this.authService.getToken() || "";
    const cloned = httpRequest.clone({
      setHeaders: {
        "Content-Type": ContentType,
        Authorization: Authorization,
      },
    });

    return next.handle(cloned).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          return this.authService.updateAccessToken().pipe(
            switchMap((authRes) => {
              this.authService.setIdentityParams(authRes.success);
              const authReq = cloned.clone({
                setHeaders: {
                  Authorization: this.authService.getToken(),
                },
              });
              return next.handle(authReq);
            }),
            catchError((err) => {
              if (
                // Bypass auto-logout on 401 (in local only) for dashboard widget endpoints
                !(httpRequest.url.includes(Constants.widgetApi.baseURL) &&
                  Constants.environment === "local")
              ) {
                this.authService.logOut();
              }
              return throwError(err);
            }),
          );
        }
        return throwError(err);
      }),
    );
  }
}

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class LoaderService {
  loaderTimeout = 0;
  visibleRequestCount: number = 0;
  totalRequestCount: number = 0;
  loaderSubject = new BehaviorSubject(null);
  constructor() {
  }

  public addRequest(hideLoading: boolean): void {
    if (!hideLoading) {
      this.visibleRequestCount += 1;
    }

    this.totalRequestCount += 1;
    this.loaderSubject.next(this.visibleRequestCount);
  }

  public removeRequest(hideLoading: boolean): void {
    if (!hideLoading) {
      this.visibleRequestCount -= 1;
    }

    this.totalRequestCount -= 1;
    this.loaderSubject.next(this.visibleRequestCount);
  }

  public reset(): void {
    this.visibleRequestCount = this.totalRequestCount = 0;
    this.loaderSubject.next(this.visibleRequestCount);
  }

  public getLoading(): boolean {
    return this.totalRequestCount > 0;
  }
}
